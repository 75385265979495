import styled from 'styled-components';
import avatarCards from './avatar-cards.webp';

const CardsDecorContainer = styled.img`
  position: absolute;
  left: -20%;
  top: 0%;
  z-index: 2;
  width: 3.5rem;
  height: 3.5rem;
`;

export const CardsDecorLabel = () => <CardsDecorContainer src={avatarCards} alt="" />;
