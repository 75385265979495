import { Client } from 'boardgame.io/react';
import { SocketIO } from 'boardgame.io/multiplayer';
// import Display from './Portals/Portals';
import { G, Poker } from '@magicyard/magicpoker-game/src/Game';
import { usePlatformDisplay } from '@magicyard/shared/platform/hooks/usePlatformDisplay';
import { StateConfigExternal } from '@magicyard/shared/platform/hooks/usePlatformDisplayTypes';
import { EffectsBoardWrapper } from 'bgio-effects/react';
import BoardContainer from './BoardContainer';
import React from 'react';
import { assertIsDefined } from '@magicyard/utils/typeUtils';
import { ScreenLayout } from './AppContainer';

export const getMultiplayerMode = (serverURL: string): ReturnType<typeof SocketIO> => {
  console.log(`Running using SocketIO() multiplayer with server: ${serverURL}`);
  return SocketIO({ server: serverURL });
};

export const board = EffectsBoardWrapper<G>(BoardContainer, {
  // Delay passing the updated boardgame.io state to your board
  // until after the last effect has been triggered.
  updateStateAfterEffects: true,
});

type State = {
  children: React.ReactElement;
};

type StartParams = { url: string };

export type Props = React.ComponentProps<ReturnType<typeof Client>>;

const Loading = () => <ScreenLayout>Loading...</ScreenLayout>;

const BoardSetup = (props: Props): React.ReactElement | null => {
  const state = usePlatformDisplay<State, StartParams>(
    {
      onLoading: () => ({ children: <Loading /> }),
      onYardAndDisplay: ({ communication, display, yard }) => ({ children: <>Connected to yard. {display.code}</> }),
      onGame: () => {
        const urlParams = new URLSearchParams(window.location.search);
        const serverURL = urlParams.get('serverURL') || process.env.REACT_APP_GAME_START_SERVER_URL;

        assertIsDefined(serverURL);

        const ClientApp = Client({
          loading: Loading,
          game: Poker,
          board,
          debug: !window.location.search.includes('hidePanel'),
          multiplayer: getMultiplayerMode(serverURL),
        });
        return {
          children: <ClientApp {...props} />,
        };
      },
      onLoadingGame: () => ({ children: <Loading /> }),
      onQueue: () => ({ children: <>Unexpected</> }),
    },
    {
      displayId: null,
    },
    'magicpoker'
  );

  return state.children;
};

export default BoardSetup;

export const onGame: StateConfigExternal<{ children: React.ReactElement }, { url: string }>['onGame'] = ({
  yard,
  gameStartArgs,
  communication,
  display,
}) => {
  const urlParams = new URLSearchParams(new URL(gameStartArgs.url).search);
  const matchID = urlParams.get('matchID') || process.env.REACT_APP_MATCH_ID || 'default';
  const serverURL = urlParams.get('serverURL') ?? undefined;

  assertIsDefined(serverURL, 'serverURL must be defined in url params');

  const ClientApp = Client({
    game: Poker,
    board,
    debug: !window.location.search.includes('hidePanel'),
    multiplayer: getMultiplayerMode(serverURL),
  });

  return {
    children: <ClientApp matchID={matchID} playerID="0" />,
  };
};
