import { useImgRectContext } from '../context/RectContext';
import { useMemo } from 'react';
import { Coordinates } from './types';

export type TableWinner = Coordinates;

const useTableWinner = (): TableWinner | undefined => {
  const rect = useImgRectContext();

  const coords = useMemo(() => {
    if (rect) {
      return {
        x: rect.x + rect.width * 0.5,
        y: rect.y + rect.height * 0.65,
      };
    }
    return;
  }, [rect]);

  return coords;
};

export default useTableWinner;
