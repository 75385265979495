import { useRef } from 'react';
import AppContainer from './AppContainer';
import { ImgRectContext } from './context/RectContext';
import GameLog from './components/GameLog';
import { useGameContext } from './context/GameContext';
import GameRenderer from './Renderers';
import useTableRect from './utils/hooks/useTableRect';
import Table from './components/Table';

const Board = () => {
  const { G } = useGameContext();
  const tableImgRef = useRef<HTMLImageElement>(null);
  const rect = useTableRect(tableImgRef);

  return (
    <AppContainer>
      <GameLog gameLog={G.gameLog} />
      <ImgRectContext.Provider value={rect}>
        <GameRenderer />
      </ImgRectContext.Provider>
      <Table ref={tableImgRef} />
    </AppContainer>
  );
};

export default Board;
