import enteredAnimation from './entered.json';
import enteringAnimation from './entering.json';
import exitingAnimation from './exiting.json';
import Lottie, { Options } from 'react-lottie';
import { Transition, TransitionStatus } from 'react-transition-group';
import type { FC } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  transform: scale(4);
`;

export type WinnerEffectAnimationProps = {
  show: boolean;
  duration?: number;
};

const WinnerEffectAnimation: FC<WinnerEffectAnimationProps> = ({ show, duration = 300 }) => {
  const transitionData: Record<TransitionStatus, Options['animationData'] | undefined> = {
    entering: enteringAnimation,
    entered: enteredAnimation,
    exiting: exitingAnimation,
    exited: undefined,
    unmounted: undefined,
  };
  const defaultOptions = (state: TransitionStatus): Options => ({
    loop: true,
    autoplay: true,
    animationData: transitionData[state],
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  });

  return (
    <Transition in={show} timeout={duration}>
      {(state) => {
        const options = defaultOptions(state);
        return <Container>{options && <Lottie options={options} height={'100%'} width={'100%'} />}</Container>;
      }}
    </Transition>
  );
};

export default WinnerEffectAnimation;
