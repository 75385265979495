import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './css/global.css';
import BoardSetup, { board, getMultiplayerMode } from './BoardSetup';
import reportWebVitals from './reportWebVitals';
import type {} from 'styled-components/cssprop';
import { Client } from 'boardgame.io/react';
import { assertIsDefined } from '@magicyard/utils/typeUtils';
import { useFullscreen } from '@magicyard/shared/platform/hooks/useTryFullscreen';
import useForceScale from '@magicyard/shared/platform/hooks/useForceScale';
import { Poker } from '@magicyard/magicpoker-game/src/Game';

const urlParams = new URLSearchParams(window.location.search);
const matchID = urlParams.get('matchID') || process.env.REACT_APP_MATCH_ID || 'default';

const getDemoData = (): { matchId: string; displayId: string | null; serverURL: string } | null => {
  const params = new URLSearchParams(window.location.search);
  const matchId = params.get('matchID');
  const displayId = params.get('displayId');
  const serverURL = urlParams.get('serverURL') || process.env.REACT_APP_GAME_START_SERVER_URL;
  if (matchId === null) {
    return null;
  }

  assertIsDefined(serverURL, 'serverURL must be defined in url params');

  return { matchId: matchId, displayId: displayId, serverURL: serverURL };
};

const demoData = getDemoData();

if (demoData !== null) {
  const ClientApp = Client({
    game: Poker,
    board,
    debug: true,
    multiplayer: getMultiplayerMode(demoData.serverURL),
  });

  const Wrapper = () => {
    useFullscreen();
    useForceScale('#root');

    return <ClientApp matchID={demoData.matchId} playerID="0" />;
  };

  ReactDOM.render(
    <React.StrictMode>
      <Wrapper />
    </React.StrictMode>,
    document.getElementById('root')
  );
} else {
  ReactDOM.render(
    <React.StrictMode>
      <BoardSetup playerID="0" matchID={matchID} />
    </React.StrictMode>,
    document.getElementById('root')
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
