import { Howl } from 'howler';

const searchParams = new URLSearchParams(window.location.search);
const html5Fallback = searchParams.get('version_name')?.toLowerCase().includes('android') === true;

export default function playAudio(fileName: string) {
  if (html5Fallback) {
    new Audio(fileName).play();
    return;
  }
  const howl = new Howl({ src: fileName, autoplay: true, html5: html5Fallback });
  howl.once('end', () => {
    howl.unload();
  });
}
