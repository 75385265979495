import styled from 'styled-components';
import books from './books.webp';

const Img = styled.img`
  position: absolute;
  z-index: 1;
  bottom: 0vw;
  left: 1vw;
  width: 8.8vw;
`;

const Books = () => {
  return <Img src={books} />;
};

export default Books;
