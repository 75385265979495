import styled from 'styled-components';
import windows from './windows.webp';

const Img = styled.img`
  position: absolute;
  z-index: 0;
  top: 5px;
  right: 0;
  height: 50vh;
`;

const Windows = () => {
  return <Img src={windows} />;
};

export default Windows;
