import { GamePhase } from '@magicyard/magicpoker-game/src/Game';
import { useGameContext } from '../context/GameContext';
import { Z_POSITIONS } from '../utils/consts';

const WinPhaseOverlay = () => {
  const {
    ctx: { phase },
  } = useGameContext();

  if (phase === GamePhase.AnnounceWinners) {
    return (
      <div
        style={{
          zIndex: Z_POSITIONS['WIN_PHASE_OVERLAY'],
          background: 'rgba(0, 0, 0, 0.3)',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }}
      ></div>
    );
  }

  return null;
};

export default WinPhaseOverlay;
