import React from 'react';
import styled from 'styled-components';

export type GameLogProps = {
  gameLog: string[];
  displayMessageLimit?: number;
};

const GameLogStyle = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  max-width: 35%;
  min-height: 76px;
  background: transparent;
  z-index: 500;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 14px;
  color: white;
  font-size: 1.6rem;
  line-height: 1.3;

  span {
    color: red;
  }
`;

const LogLineStyle = styled.div`
  background: rgba(0, 0, 0, 0.4);
  width: 100%;
  padding: 5px;
`;

const formatLogLine = (line: string): string => {
  return line
    .split(' ')
    .map((word) => {
      // Hearts and diamonds are red
      if (word.includes('\u2665') || word.includes('\u2666')) {
        return `<span>${word}</span>`;
      } else {
        return word;
      }
    })
    .join(' ');
};

const LogLine: React.FC<{
  logLine: string;
  displayTimerMS?: number;
  onDone?: () => unknown;
}> = ({ logLine, displayTimerMS = 0, onDone = () => {} }) => {
  const [didRender, setDidRender] = React.useState(false);

  React.useEffect(() => {
    if (displayTimerMS) {
      setTimeout(() => setDidRender(true), displayTimerMS);
    }
    onDone();
  }, [displayTimerMS, onDone]);

  if (didRender) {
    return null;
  }
  return (
    <LogLineStyle
      dangerouslySetInnerHTML={{
        __html: logLine,
      }}
    />
  );
};

const GameLog: React.FC<GameLogProps> = ({ gameLog, displayMessageLimit = 4 }) => {
  // const [count] = React.useState(gameLog.length);

  // if (!gameLog.length || gameLog.length <= count) {
  //     return null;
  // }

  // const newEntries = gameLog.slice(count - gameLog.length);

  const newEntries = gameLog.slice(-Math.min(gameLog.length, displayMessageLimit));

  return (
    <GameLogStyle>
      {newEntries.map((l, i) => (
        <LogLine key={i} logLine={formatLogLine(l)} />
      ))}
    </GameLogStyle>
  );
};

export default GameLog;
