import { FC } from 'react';

//  Components
import Avatar from '../Avatar/Avatar';

import { useGameContext } from '../../context/GameContext';

import { GamePhase, IPlayer } from '@magicyard/magicpoker-game/src/Game';
import { extractAnnouncementState, extractPlayState, extractDealerState } from './utils';
import useWinnersBestCards from '../../utils/hooks/useWinnersBestCards';
import useSequenceWinners from '../../utils/hooks/useSequenceWinners';

interface PlayerProps {
  player: IPlayer;
}

const Player: FC<PlayerProps> = ({ player }) => {
  const {
    ctx: { currentPlayer, phase },
    G: { dealer, smallBlind, bigBlind, playerAtSeat },
  } = useGameContext();

  const winnerIDs = useSequenceWinners();

  const playState = extractPlayState(player, parseInt(currentPlayer));

  const dealerState = extractDealerState(playerAtSeat, player, bigBlind, smallBlind, dealer);

  const winnerBestCards = useWinnersBestCards();
  const handWinningCardsLocation = !!winnerBestCards[0] && winnerBestCards[0].handWinningCardsLocation;

  const announcementState = extractAnnouncementState(player, phase as GamePhase, winnerIDs);

  return (
    <Avatar
      playState={playState}
      announcementState={announcementState}
      src={player.avatarUrl}
      name={player.name}
      value={player.stack}
      cards={player.cards}
      showCards={player.cardsOpen}
      winningCards={handWinningCardsLocation}
      dealerState={dealerState}
      isBot={player.isBot}
    />
  );
};

export default Player;
