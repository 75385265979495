import { useGameContext } from '../context/GameContext';
import CommunityCards from '../components/Cards';
import useTableCards from '../tableCoordinateResolvers/useTableCards';
import PositioningContainer from './components/PositioningContainer';
import useWinnersBestCards from '../utils/hooks/useWinnersBestCards';
import { useEffectListener } from 'bgio-effects/dist/react';
import { Effects } from '@magicyard/magicpoker-game/src/effectsConfig';
import { ICard } from '@magicyard/magicpoker-game/src/Cards';

import { useEffect, useState } from 'react';

const CardsRenderer = () => {
  const coords = useTableCards();
  const { G } = useGameContext();

  const winnerBestCards = useWinnersBestCards();

  const communityWinningCardsLocation = !!winnerBestCards[0] && winnerBestCards[0].communityWinningCardsLocation;

  const [cards, setCards] = useState<ICard[]>(G.table.cards);

  // This runs before state (G) change, animmate
  useEffectListener(
    // @ts-expect-error
    Effects.FlipCard,
    ({ card }: { card: ICard }) => {
      setCards((prevCards) => {
        return [...prevCards, card];
      });
    },
    [setCards]
  );

  // This runs after state change, sync to G
  useEffect(() => {
    setCards(G.table.cards);
  }, [G.table.cards]);

  if (!coords) {
    return null;
  }

  return (
    <PositioningContainer
      position={coords}
      css={`
        height: ${coords.cardsContainerHeight}px;
        width: ${coords.cardsContainerWidth}px;
      `}
    >
      <CommunityCards cards={cards} winningCards={communityWinningCardsLocation} />
    </PositioningContainer>
  );
};

export default CardsRenderer;
