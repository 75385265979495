import { countHumanPlayers } from '@magicyard/magicpoker-game/src/Game';
import Books from './components/Decor/Books';
import Lights from './components/Decor/Lights';
import Plant from './components/Decor/Plant';
import Windows from './components/Decor/Windows';
import { useGameContext } from './context/GameContext';
import { useMixpanel } from './context/MixpanelContext';
import { FC, useEffect } from 'react';
import styled from 'styled-components';
import background from './background.jpg';

export const ScreenLayout = styled.div`
  width: 100vw;
  height: 100vh;
  background: url('${background}') no-repeat center / cover;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AppContainer: FC = ({ children }) => {
  const mixpanel = useMixpanel();
  const {
    G: { matchID, players },
  } = useGameContext();

  useEffect(() => {
    mixpanel?.track('Game Started - Display', {
      matchID,
      numOfPlayers: countHumanPlayers({ players }),
    });
  }, [mixpanel, matchID, players]);

  return (
    <ScreenLayout>
      <Plant />
      <Books />
      <Windows />
      <Lights />
      {children}
    </ScreenLayout>
  );
};

export default AppContainer;
