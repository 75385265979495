import { useImgRectContext } from '../context/RectContext';
import { useMemo } from 'react';
import { Coordinates } from './types';

const useTableMainPot = (): Coordinates | undefined => {
  const rect = useImgRectContext();

  const coords = useMemo(() => {
    if (rect) {
      return {
        x: rect.x + rect.width * 0.5,
        y: rect.y + rect.height * 0.23,
      };
    }
    return;
  }, [rect]);

  return coords;
};

export default useTableMainPot;
