import { FC, useEffect, useState } from 'react';

//  Style
import styled from 'styled-components';
//  Components
import { FlippingCard, Card } from '@magicyard/magicpoker-components';
import { motion, useAnimation } from 'framer-motion';
import { ICard } from '@magicyard/magicpoker-game/src/Cards';

const StyledCommunityCards = styled.div`
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 1px;
  ${Card} {
    width: 100%;
    height: auto;
  }
`;

interface CommunityCardProps {
  cards: ICard[];
  winningCards: number[];
}

const MovingAnimatedCard: FC<{ card: ICard; isLosing: boolean }> = ({ card, isLosing }) => {
  const controls = useAnimation();

  const moveAnimationDuration = 0.5;
  const [isFlipped, setIsFlipped] = useState(true);

  // Use this to have isFlipped set AFTER y moves to 0.
  useEffect(() => {
    controls.start({ y: 0 }).then(() => {
      setIsFlipped(false);
    });
  }, [controls]);

  return (
    <>
      <motion.div transition={{ duration: moveAnimationDuration }} initial={{ y: '-100%' }} animate={controls}>
        <FlippingCard shadow={true} fileName={card.fileName} isTransparent={isLosing} isFlipped={isFlipped} />
      </motion.div>
    </>
  );
};

const CommunityCards: FC<CommunityCardProps> = ({ cards, winningCards }) => {
  const isLosingCard = (cardIndex: number): boolean => {
    if (!winningCards.length) {
      // no one has won yet
      return false;
    }
    const isWinningCard = Array.isArray(winningCards) && winningCards.includes(cardIndex);
    return !isWinningCard;
  };

  useEffect(() => {});
  return (
    <StyledCommunityCards>
      {cards.map((card, index) => (
        <MovingAnimatedCard card={card} key={index} isLosing={isLosingCard(index)} />
      ))}
    </StyledCommunityCards>
  );
};

export default CommunityCards;
