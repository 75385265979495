import { NewStack } from '@magicyard/magicpoker-components';
import { FC } from 'react';
import mainpotChips from './mainpot-chips.webp';

export type MainPotProps = {
  amount: number;
  className?: string;
};

const MainPotComponent: FC<MainPotProps> = ({ amount, className }) => {
  return <NewStack chipURL={mainpotChips} amount={amount} className={className} />;
};

export default MainPotComponent;
