import Providers from './Providers';
import Board from './Board';
import AudioManager from './AudioManager';
import { FC } from 'react';
import { GameObject } from '@magicyard/magicpoker-game/src/Game';

const BoardSetup: FC<GameObject> = (props) => {
  return (
    <Providers {...props}>
      <AudioManager />
      <Board />
    </Providers>
  );
};

export default BoardSetup;
