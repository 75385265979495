import { useEffect } from 'react';
import useSequenceWinners from '../utils/hooks/useSequenceWinners';
import playAudio from './playAudio';

const usePlayAudioOnWinnerSequence = () => {
  const winnerIDs = useSequenceWinners();

  useEffect(() => {
    if (!!winnerIDs.length) {
      playAudio('/sounds/winner.mp3');
    }
  }, [winnerIDs]);
};

export default usePlayAudioOnWinnerSequence;
