import { bestHandCardsLocation } from '@magicyard/magicpoker-game/src/Deck';
import { getPlayer } from '@magicyard/magicpoker-game/src/Game';
import { useGameContext } from '../../context/GameContext';
import { useMemo } from 'react';
import useSequenceWinners from './useSequenceWinners';

const useWinnersBestCards = () => {
  const {
    G: {
      players,
      table: { cards },
      everyoneFolded,
    },
  } = useGameContext();

  const winnerIDs = useSequenceWinners();
  const winnersBestCards = useMemo(
    () => winnerIDs.map((winnerID) => bestHandCardsLocation(getPlayer({ players }, winnerID), cards, everyoneFolded)),
    [players, cards, winnerIDs, everyoneFolded]
  );

  return winnersBestCards;
};

export default useWinnersBestCards;
