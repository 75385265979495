import { GameObject } from '@magicyard/magicpoker-game/src/Game';
import GameContext from './context/GameContext';
import { InitializedMixpanel } from './context/MixpanelContext';
import type { FC } from 'react';
import { ThemeProvider } from 'styled-components';
import theme from './theme/theme';

type ProvidersProps = GameObject;

const Providers: FC<ProvidersProps> = ({ children, ...props }) => {
  return (
    <InitializedMixpanel>
      <GameContext.Provider value={props}>
        <ThemeProvider theme={theme}>{children}</ThemeProvider>
      </GameContext.Provider>
    </InitializedMixpanel>
  );
};

export default Providers;
