import { createContext, useContext } from 'react';
import type { GameObject } from '@magicyard/magicpoker-game/src/Game';
import { GamePhase, getPlayer } from '@magicyard/magicpoker-game/src/Game';
export type GameContextType = GameObject;

const GameContext = createContext<GameContextType | null>(null);

export const useGameContext = (): GameContextType => {
  const game = useContext(GameContext);
  if (!game) throw new Error('Game context not found');
  return game;
};

export default GameContext;
export { GamePhase, getPlayer };
