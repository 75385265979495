import React, { createContext, useContext, useEffect } from 'react';
import mixpanel, { Mixpanel } from 'mixpanel-browser';

const context = createContext<Mixpanel | null>(null);

export const MixpanelProvider = context.Provider;

export const MixpanelConsumer = context.Consumer;

export function useMixpanel(): Mixpanel | null {
  return useContext(context);
}

export const InitializedMixpanel = (props: { children: React.ReactNode }) => {
  useEffect(() => {
    // eslint-disable-next-line import/no-named-as-default-member
    mixpanel.init('2086bada501bab933572b6f6905eb3bc', {
      debug: true,
      api_host: 'https://mp.magicpoker.app',
    });

    // eslint-disable-next-line import/no-named-as-default-member
    mixpanel.track('Mixpanel Loaded [Platform Client]');
  }, []);

  return <MixpanelProvider value={mixpanel}>{props.children}</MixpanelProvider>;
};
