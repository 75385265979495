import { useState, useEffect, useCallback, useMemo } from 'react';

const useTableRect = (tableRef: React.MutableRefObject<HTMLImageElement | null>): DOMRect | null => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [rect, setRect] = useState<DOMRect | null>(null);

  const onLoad = () => {
    setImageLoaded(true);
  };

  useEffect(() => {
    const tableImg = tableRef.current;
    if (tableImg) {
      tableImg.addEventListener('load', onLoad);
    }

    return () => {
      if (tableImg) {
        tableImg.removeEventListener('load', onLoad);
      }
    };
  }, [tableRef]);

  const imageRect = useMemo(() => {
    const tableImg = tableRef.current;
    if (imageLoaded && tableImg) {
      if (!rect) {
        setRect(tableImg.getBoundingClientRect());
      }
      return rect;
    }
    return null;
  }, [tableRef, imageLoaded, rect]);

  const setImageRect = useCallback(() => {
    const tableImg = tableRef.current;
    if (imageLoaded && tableImg) {
      setRect(tableImg.getBoundingClientRect());
    }
  }, [tableRef, imageLoaded]);

  useEffect(() => {
    window.addEventListener('resize', setImageRect);
    return () => {
      window.removeEventListener('resize', setImageRect);
    };
  }, [setImageRect]);

  return imageRect;
};

export default useTableRect;
