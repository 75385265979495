import { Effects } from '@magicyard/magicpoker-game/src/effectsConfig';
import { useEffectListener } from 'bgio-effects/dist/react';
import playAudio from './playAudio';

const usePlayAudioOnEffect = (effect: Effects, fileName: string) => {
  useEffectListener(
    effect,
    () => {
      playAudio(fileName);
    },
    []
  );
};

export default usePlayAudioOnEffect;
