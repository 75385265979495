import { useGameContext, GamePhase, getPlayer } from '../context/GameContext';
import useTableWinner from '../tableCoordinateResolvers/useTableWinner';
import useSequenceWinners from '../utils/hooks/useSequenceWinners';
import { createStyle } from '../utils/utils';
import { useMemo } from 'react';
import styled from 'styled-components';
import announcement from './announcement.webp';

const WinnerContainer = styled.div`
  position: relative;
  background: url('${announcement}') no-repeat center / contain;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 2rem 13rem;
`;

const Winner = styled.div`
  align-self: center;
  justify-self: center;
  text-transform: uppercase;
  font-size: 4rem;
  color: white;
`;

const WinnerRenderer = () => {
  const {
    G: { players },
    ctx,
  } = useGameContext();
  const coords = useTableWinner();

  const winnerIDs = useSequenceWinners();

  const isWinningPhase = ctx.phase === GamePhase.AnnounceWinners && !!winnerIDs.length;

  const winningHand = useMemo(() => {
    if (isWinningPhase && !!winnerIDs.length) {
      return getPlayer({ players }, winnerIDs[0])?.bestHand?.name;
    }
    return '';
  }, [winnerIDs, players, isWinningPhase]);

  if (!winningHand || !coords) {
    return null;
  }

  return (
    <div
      style={{
        position: 'absolute',
        zIndex: 500,
        transform: 'translate3d(-50%, -50%, 0)',
        ...createStyle(coords),
      }}
    >
      <WinnerContainer>
        <Winner>{winningHand}</Winner>
      </WinnerContainer>
    </div>
  );
};

export default WinnerRenderer;
